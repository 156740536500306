<!-- @format -->
<script>
	import { procedure, transmitter, bankruptIsPerson, bankruptIsOrganization, bankruptType, options } from 'base_stores';
	import { fetchDelete, fetchGet, fetchPost, fetchPut } from 'utils/fetch';
	import { isPresent, isBlank, toArray } from 'utils/tools';
	import Modal, { showModal } from '~/svelte/components/modal.svelte';
	import Header from '~/svelte/components/header.svelte';
	import AutosaveDatepicker from '~/svelte/components/ui/autosave_datepicker.svelte';
	import AutosaveSelect2Search from '~/svelte/components/ui/autosave_select2-search.svelte';
	import Select2 from '~/svelte/components/ui/select2.svelte';
	import AutosaveTextarea from '~/svelte/components/ui/autosave_textarea.svelte';
	import CounterpartyModal from '~/svelte/counterparties/_modal.svelte';
	import AutosaveMultipleInput from '~/svelte/components/ui/autosave_multiple_input.svelte';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';
	import Dashboard from './dashboard.svelte';
	import OCRScan from './_ocr_scan.svelte';
	import Message from '~/svelte/components/message.svelte';
	import PossessionsBlock from '~/svelte/dcts/fields_components/blocks/possessions/possessions.svelte';
	import DraftPossessionsBlock from './draft_possessions.svelte';
	import DraftRequirementsBlock from './draft_requirements.svelte';
	import SwitchBtn from '~/svelte/components/ui/switch_btn.svelte';
	import moment from 'moment';
	import AutosaveCheckbox from '../components/ui/autosave_checkbox.svelte';
	import AutosaveDadataInputFio from '../components/ui/autosave_dadata_input_fio2.svelte';
	import AutosaveDadataParty from '~/svelte/components/ui/autosave_dadata_input_party.svelte';

	const placeholders = { Person: 'ФИО', Organization: 'ООО «Компания»' };
	const docTypeOptions = [
		{ id: 'null', text: 'Без вида' },
		{ id: 'publication_kommersant', text: 'Публикация ”Коммерсант”' },
		{ id: 'publication_fedresurs', text: 'Публикация ”Федресурс”' },
		{ id: 'bankrupt_passport', text: 'Паспорт должника' },
		{ id: 'bankrupt_spouse_passport', text: 'Паспорт супруга(и)' },
	];

	let forceSave = false;
	let counterpartyOptions = [];
	let correspondentOptions = [];
	let outgoingOptions = [];
	let counterpartyValue;
	let regionId;
	let selectedCounterpartyId;
	let newCounterpartyId;
	let correspondentKind;
	let currentCorrespondentKind;
	let currentOutgoingId;
	let switchValue;
	let fetching = false;
	let currentRegionId;
	let deleteIconDisabled = false;

	$: procedureId = $procedure && $procedure.id;
	$: answer = $transmitter && $transmitter.answer;
	$: answerId = answer && answer.id;
	$: answerReplyDateValue = (answer && answer.reply_date) || moment();
	$: bankrupt = $procedure && $procedure.bankrupt;
	$: addresseeRegionId = answer && answer.addressee_region_id;
	$: bankruptFullName = (answer && answer.bankrupt_full_name) || bankruptUsedName();
	$: if (procedureId) {
		getOutgoingOptions();
	}

	$: code =
		(answer && answer.correspondent_kind) ||
		answer.counterparty_role ||
		(!!answer.arbitrary_addressee && 'correspondent') ||
		(!!answer.bankrupt_full_name && 'bankrupt') ||
		(!!answer.kdl && 'kdl') ||
		(!!answer.administration && 'administration') ||
		undefined;

	$: if (answer && answer.outgoing_id !== currentOutgoingId) {
		currentOutgoingId = answer.outgoing_id;
		currentRegionId = regionId = addresseeRegionId;
		correspondentKind = currentCorrespondentKind = code;
		getCorrespondentOptions();
	}

	$: if (isBlank(correspondentKind)) {
		correspondentKind = code;
		currentCorrespondentKind = correspondentKind;
	}

	$: if (isBlank(regionId) && (correspondentKind || courtRegionId)) {
		regionId = (isPresent(correspondentKind) && addresseeRegionId) || courtRegionId;
		currentRegionId = regionId;
	}

	$: answerCounterpartyId = answer && (answer.addressee_id || answer.counterparty_id);
	$: selectedCounterpartyId = newCounterpartyId || answerCounterpartyId;
	$: courtRegionId = $procedure && $procedure.arbitr_case.court_region_id;
	$: correspondentKindOptions = metaDataAnswers[answer.kind].correspondentKindOptions;
	$: correspondentValue = (correspondentOptions && correspondentOptions.find(option => String(option.id) === String(selectedCounterpartyId))) || { id: null };
	$: counterpartyValue = counterpartyOptions.find(option => String(option.id) === String(selectedCounterpartyId)) || { id: null };

	$: if (answerId && correspondentKind && answer.addressee_id && !correspondentOptions.find(option => String(option.id) === String(answer.addressee_id))) {
		if (!fetching) {
			getCorrespondentOptions();
		}
	}

	$: bankruptSurname = bankrupt && bankrupt.surname;
	$: bankruptName = bankrupt && bankrupt.name;
	$: bankruptPatronymic = bankrupt && bankrupt.patronymic;
	$: scan = answer && answer.scan;

	$: if (answer.outgoing_id === currentOutgoingId && correspondentKind !== currentCorrespondentKind) {
		nullifyAddresseeId();
		currentCorrespondentKind = correspondentKind;
	}

	$: if (answer.outgoing_id === currentOutgoingId && regionId !== currentRegionId) {
		nullifyAddresseeId();
		currentRegionId = regionId;
	}

	$: if (correspondentKind) {
		if ($options.correspondent && ['bankrupt', 'correspondent', 'kdl', 'administration', 'court_usual'].includes(correspondentKind)) {
			correspondentOptions = [];
		} else if (['bank', 'creditor'].includes(correspondentKind)) {
			getCounterpartyOptions();
		} else {
			let region = ['favt', 'fsis', 'rsa'].includes(correspondentKind) || isBlank(regionId) ? 'all' : String(regionId);

			if ($options.correspondent && $options.correspondent[correspondentKind]) {
				if (isBlank($options.correspondent[correspondentKind][region])) {
					region = 'all';
				}
				correspondentOptions = $options.correspondent[correspondentKind][region];
			} else {
				if (!fetching) {
					getCorrespondentOptions();
				}
			}
		}
	} else {
		correspondentOptions = [];
	}

	const nullifyAddresseeId = () => {
		$transmitter.answer = { ...answer, addressee_id: null };
	};

	$: if (isPresent(answerId) && location.pathname.match(/^\/answers\/new/)) {
		window.history.replaceState({ answerId }, '', `/answers/${answerId}`);
	}

	$: showPossessionsBlocks =
		answer.kind == 'normal' &&
		(['gibdd', 'gtn', 'rosreestr', 'mchs'].includes(correspondentKind) || (['rosgvardiya', 'rsa'].includes(correspondentKind) && $bankruptIsPerson));

	$: showRequirementBlock = answer.kind == 'requirement';

	$: if (String(answerCounterpartyId) == String(newCounterpartyId)) {
		newCounterpartyId = null;
	}

	const getCorrespondentOptions = async () => {
		fetching = true;
		let region = String(regionId);

		try {
			const response = await fetchGet('/api/private/answers/get_options_by_correspondent_kind', { correspondent_kind: correspondentKind });
			if (isBlank($options.correspondent)) {
				$options = { ...$options, ...response };
			} else if (isBlank($options.correspondent[correspondentKind])) {
				$options.correspondent[correspondentKind] = response.correspondent[correspondentKind] || [];
			} else {
				$options.correspondent[correspondentKind]['all'] = response.correspondent[correspondentKind]?.['all'] || [];
				if (isPresent(regionId)) {
					$options.correspondent[correspondentKind][region] = response.correspondent[correspondentKind]?.[region] || [];
				}
			}
			if (isBlank($options.correspondent[correspondentKind]?.[region])) {
				region = 'all';
			}
			correspondentOptions = $options.correspondent[correspondentKind]?.[region] || [];
		} catch (error) {
			console.error(error);
			throw error;
		} finally {
			fetching = false;
		}
	};

	const getOutgoingOptions = async () => {
		await fetchGet('/api/private/documents/as_options', { dct_category: 'outgoing', procedure_id: procedureId }).then(response => {
			let dcts = response.dcts.sort((a, b) => a.out_full_name.localeCompare(b.out_full_name));
			outgoingOptions = dcts.map(d => {
				return { id: d.id, text: d.out_full_name, kind: d.correspondent_kind };
			});
		});
	};

	const setCounterparty = async newId => {
		await getCounterpartyOptions();
		newCounterpartyId = newId;
		forceSave = true;
	};

	const addCorrespondentOption = async text => {
		const params = { text, addressee_kind: correspondentKind, region_id: regionId };
		const response = await fetchPost('/api/private/addressees/create_and_return_option', params);
		const option = response.option;
		$options.correspondent[correspondentKind][String(regionId)] = response.options;
		correspondentOptions = response.options;

		return option;
	};

	$: correspondentIdField = {
		required: true,
		disabled: !procedureId || !correspondentKind,
		attribute: 'addressee_id',
		model: 'answer',
		childModel: 'procedure',
		childId: procedureId,
		url: '/api/private/answers',
		id: answerId,
		label: metaDataAnswers[answer.kind].correspondentIdLabel,
		options: correspondentOptions,
		placeholder: 'Выбрать отправителя',
		value: correspondentValue,
		requiredAttributes: { kind: answer.kind },
		addOption: addCorrespondentOption,
	};

	$: correspondentFullNameField = {
		required: true,
		disabled: !answerId || !correspondentValue || !correspondentKind,
		attribute: 'full_name',
		model: 'answer',
		childModel: 'procedure',
		childId: procedureId,
		url: '/api/private/answers',
		id: answerId,
		placeholder: 'Введите полное наименование',
		label: 'Полное наименование',
		value: correspondentValue && correspondentValue.full_name,
	};

	$: regionIdField = {
		attribute: 'correspondent_region',
		model: 'answer',
		label: metaDataAnswers[answer.kind].regionLabel,
		placeholder: 'Выбрать регион',
		options: $options.region,
		hintData: { freeMessage: 'AI заполнил на основании Наименование суда' },
	};

	const blockHandleChange = value => value === 'new';

	const handleBlocking = () => {
		counterpartyValue = { id: null };
		showModal('form-counterparty-modal');
	};

	const checkSuitabilityCounterparty = item => {
		let counterparty = counterpartyOptions.find(opt => opt.id == item.id);
		if (isPresent(counterparty?.roles)) {
			return counterparty.roles.includes(correspondentKind);
		} else {
			return true;
		}
	};

	const actionInUnsuitabilityCounterparty = async item => {
		let counterparty = counterpartyOptions.find(opt => opt.id == item.id);
		let roles = [...counterparty.roles, correspondentKind];
		await fetchPut(`/api/private/counterparties/${counterparty.id}`, { roles: roles });
		counterpartyOptions.find(opt => opt.id == item.id).roles = roles;
	};

	$: counterpartyId = {
		required: true,
		disabled: !procedureId || !correspondentKind,
		attribute: 'counterparty_id',
		model: 'answer',
		childModel: 'procedure',
		childId: procedureId,
		url: '/api/private/answers',
		id: answerId,
		checkSuitability: checkSuitabilityCounterparty,
		actionInUnsuitability: actionInUnsuitabilityCounterparty,
		label: metaDataAnswers[answer.kind].correspondentIdLabel,
		options: counterpartyOptions,
		placeholder: 'Выбрать отправителя',
		value: counterpartyValue,
		requiredAttributes: ['bank', 'creditor'].includes(correspondentKind) ? { correspondent_kind: correspondentKind, kind: answer.kind } : { kind: answer.kind },
	};

	$: formFields = {
		replyDate: {
			required: answer.kind == 'requirement',
			disabled: !procedureId,
			attribute: 'reply_date',
			model: 'answer',
			childModel: 'procedure',
			childId: procedureId,
			url: '/api/private/answers',
			id: answerId,
			label: metaDataAnswers[answer.kind].dateLabel,
			placeholder: 'дд.мм.гггг',
			value: answerReplyDateValue,
			requiredAttributes: { kind: answer.kind },
		},
		name: {
			required: answer.kind == 'other',
			model: 'answer',
			attribute: 'additions.name',
			url: '/api/private/answers',
			id: answer.id,
			label: 'Название документа',
			value: answer.additions.name,
			placeholder: 'Введите название документа',
		},
		includeInReports: {
			model: 'answer',
			attribute: 'additions.include_in_reports',
			label: 'Включать в Отчеты АУ как ответ на запрос',
			url: '/api/private/answers',
			value: answer.additions.include_in_reports,
			id: answerId,
			text: 'Да',
		},
		docType: {
			model: 'answer',
			attribute: 'additions.doc_type',
			label: 'Вид документа',
			url: '/api/private/answers',
			value: docTypeOptions.find(option => option.id === answer.additions.doc_type),
			id: answerId,
			placeholder: 'Выбрать вид документа',
			options: docTypeOptions,
			disabled: !procedureId,
			required: false,
			childModel: 'procedure',
			childId: procedureId,
		},
		outgoingId: {
			model: 'answer',
			attribute: 'outgoing_id',
			label: answer.kind === 'requirement' ? 'Уведомление кредитора' : 'Запрос, на который поступил ответ',
			url: '/api/private/answers',
			value: outgoingOptions && outgoingOptions.find(option => option.id === answer.outgoing_id),
			id: answerId,
			placeholder: 'Выбрать запрос',
			options:
				answer.kind === 'other'
					? outgoingOptions
					: answer.kind === 'requirement'
						? outgoingOptions.filter(opt => opt.kind === 'creditor')
						: outgoingOptions.filter(opt => correspondentKindOptions.map(type => type.id).includes(opt.kind)),
			disabled: !procedureId,
			required: false,
			childModel: 'procedure',
			childId: procedureId,
			requiredAttributes: { kind: answer.kind },
		},
		content: {
			disabled: !procedureId,
			attribute: 'content',
			model: 'answer',
			childModel: 'procedure',
			childId: procedureId,
			url: '/api/private/answers',
			id: answerId,
			label: 'Содержание документа',
			placeholder: answer.kind === 'other' ? 'Введите вручную или с помощью распознавания' : 'Содержание ответа для отображения в отчете',
			value: answer && answer.content,
			rows: 9,
		},
		bankruptFullName: {
			required: true,
			disabled: !procedureId,
			attribute: 'bankrupt_full_name',
			model: 'answer',
			childModel: 'procedure',
			childId: procedureId,
			url: '/api/private/answers',
			id: answerId,
			label: 'Должник',
			placeholder: placeholders[$bankruptType],
			value: bankruptFullName,
			hintData: {
				url: `/procedures/${procedureId}`,
				linkText: 'Процедура',
				consumerNew: !answerId,
				consumer: answer,
				consumerProp: 'bankrupt_full_name',
				resource: $procedure && $procedure.bankrupt,
				resourceProp: 'surname',
			},
			data: [
				{
					name: 'bankrupt_surname',
					value: bankruptSurname,
					localizedName: 'Фамилия',
				},
				{
					name: 'bankrupt_name',
					value: bankruptName,
					localizedName: 'Имя',
				},
				{
					name: 'bankrupt_patronymic',
					value: bankruptPatronymic,
					localizedName: 'Отчество',
				},
			],
		},
		arbitraryAddressee: {
			required: true,
			disabled: !procedureId,
			attribute: 'arbitrary_addressee',
			model: 'answer',
			childModel: 'procedure',
			childId: procedureId,
			url: '/api/private/answers',
			id: answerId,
			label: 'Адресат',
			placeholder: 'Адресат',
			value: answer && answer.arbitrary_addressee,
		},
		kdl: {
			required: true,
			disabled: !procedureId,
			attribute: 'kdl',
			model: 'answer',
			childModel: 'procedure',
			childId: procedureId,
			url: '/api/private/answers',
			id: answerId,
			label: 'Наименование отправителя',
			placeholder: 'Наименование отправителя',
			value: answer && answer.kdl,
		},
		administrationOrCourtUsual: {
			required: true,
			disabled: !procedureId,
			model: 'answer',
			childModel: 'procedure',
			childId: procedureId,
			url: '/api/private/answers',
			id: answerId,
			label: 'Наименование отправителя',
			placeholder: 'Наименование отправителя',
		},
		correspondentKind: {
			attribute: 'correspondent_kind',
			model: 'answer',
			disabled: !procedureId,
			required: true,
			label: metaDataAnswers[answer.kind].correspondentKindLabel,
			placeholder: 'Выбрать вид отправителя',
			options: correspondentKindOptions,
		},
	};

	const answerDelete = async () => {
		await fetchDelete(`/api/private/answers/${answerId}`, { procedure_id: procedureId });
		location.href = '/answers';
	};

	const getCounterpartyOptions = async () => {
		counterpartyOptions = [];
		let newOptionText = correspondentKind == 'bank' ? 'Добавить банк' : 'Добавить кредитора';

		await fetchGet('/api/private/counterparties/get_options_for_roles', { possible_roles: [correspondentKind] }).then(
			response => (counterpartyOptions = [{ id: 'new', text: newOptionText }, ...response.options]),
		);
	};

	const bankruptUsedName = () => {
		if ($bankruptIsPerson) {
			return bankrupt && bankrupt.full_name;
		} else if ($bankruptIsOrganization) {
			return bankrupt && bankrupt.short_name;
		}
	};

	const metaDataAnswers = {
		normal: {
			answerName: 'Ответ на запрос',
			dateLabel: 'Дата получения ответа',
			correspondentKindLabel: 'Вид отправителя',
			correspondentKindOptions: [{ id: 'correspondent', text: 'Адресат' }, ...($options.correspondentKind || [])],
			regionLabel: 'Регион отправителя',
			correspondentIdLabel: 'Наименование отправителя',
		},
		requirement: {
			answerName: 'Входящее с требованиями',
			dateLabel: 'Дата получения входящего',
			correspondentKindLabel: 'Вид отправителя-кредитора',
			correspondentKindOptions: ($options.correspondentKind || []).map(opt => (opt.id == 'fns' ? { ...opt, text: 'Уполномоченный орган' } : opt)),
			regionLabel: 'Регион уполномоченного органа',
			correspondentIdLabel: 'Наименование отправителя-кредитора',
		},
		other: {
			answerName: 'Входящий документ',
		},
	};

	let loading = false;
	let blobsAreIndexed = false;
	let offset = 0;

	const loadNextBatch = async () => {
		if (loading) {
			return;
		}

		if (offset == 0) {
			const firstPageNumber = answer.scan[0]?.match(/_(\d+)\.jpg/);
			const lastPageNumber = answer.scan[answer.scan.length - 1]?.match(/_(\d+)\.jpg/);

			if (firstPageNumber && lastPageNumber && +firstPageNumber != +lastPageNumber && +firstPageNumber == 1 && +lastPageNumber == answer.scan.length) {
				blobsAreIndexed = true;
			}
		}

		loading = true;
		try {
			const response = await fetchGet(`/api/private/scans/${answer.scan_id}/blob_paths`, { offset: ++offset });

			const scanPaths = toArray(response.scan);

			if (isPresent(scanPaths)) {
				if (blobsAreIndexed) {
					let firstIndex = scanPaths[0]?.match(/_(\d+)\.jpg/);
					for (let i = 0; i < scanPaths.length; i++) {
						answer.scan[i + firstIndex] = scanPaths[i];
					}
				} else {
					answer.scan = [...answer.scan, ...scanPaths];
				}
			}
		} catch (error) {
			console.error(error);
		} finally {
			loading = false;
		}
	};
</script>
<Header>
	<span slot="name">
		<span role="button" tabindex="0" on:click={() => history.back()} on:keypress|stopPropagation>
			<iconify-icon icon="fa-solid:chevron-left" class="m-r-xs hoverable" />
		</span>
		{#if answer.kind === 'other'}
			{answer.additions.name || metaDataAnswers.other.answerName}
		{:else}
			{metaDataAnswers[answer.kind].answerName}
		{/if}
	</span>
	<div slot="dashboard">
		<Dashboard {answer} bind:deleteIconDisabled withPossessionsBlocks={showPossessionsBlocks} on:scanRecognized />
	</div>
</Header>
<div class="col-12 col-sm-5 mt-1 p-0 float-left height__full footer-margin" class:hidden-mobile={!switchValue}>
	<div class="overlay scrollable w-100 h-100">
		{#if answer.kind === 'other'}
			<AutosaveInput {...formFields.name} on:update />
			<AutosaveSelect2Search {...formFields.outgoingId} {blockHandleChange} {handleBlocking} bind:forceSave />
			<AutosaveSelect2Search {...formFields.docType} {blockHandleChange} {handleBlocking} bind:forceSave />
		{:else}
			<AutosaveDatepicker {...formFields.replyDate} />
			<AutosaveSelect2Search {...formFields.outgoingId} {blockHandleChange} {handleBlocking} bind:forceSave />
			<Select2 {...formFields.correspondentKind} bind:value={correspondentKind} />
			{#if correspondentKind == 'creditor' || correspondentKind == 'bank'}
				<AutosaveSelect2Search {...counterpartyId} {blockHandleChange} {handleBlocking} bind:forceSave />
			{:else if correspondentKind == 'bankrupt' && $bankruptIsPerson}
				<AutosaveMultipleInput {...formFields.bankruptFullName} forceSave={correspondentKind === 'bankrupt' && !!bankruptUsedName()} />
			{:else if correspondentKind == 'bankrupt' && $bankruptIsOrganization}
				<AutosaveInput {...formFields.bankruptFullName} forceSave={correspondentKind === 'bankrupt' && !!bankruptUsedName()} />
			{:else if correspondentKind == 'correspondent'}
				<AutosaveDadataParty {...formFields.arbitraryAddressee} />
			{:else if correspondentKind == 'kdl'}
				<AutosaveDadataInputFio {...formFields.kdl} />
			{:else if correspondentKind == 'administration'}
				<AutosaveDadataParty {...formFields.administrationOrCourtUsual} attribute={'administration'} value={answer && answer.administration} />
			{:else if correspondentKind == 'court_usual'}
				<AutosaveInput
					{...formFields.administrationOrCourtUsual}
					attribute={'full_name'}
					additionalParams={{ correspondent_kind: correspondentKind }}
					value={answer && answer.correspondent_full_name}
				/>
			{:else}
				{#if correspondentKind && !['favt', 'fsis', 'rsa', 'bankrupt', 'correspondent', 'kdl', 'administration', 'bki'].includes(correspondentKind)}
					<Select2 {...regionIdField} bind:value={regionId} />
				{/if}
				<AutosaveSelect2Search {...correspondentIdField} />
				{#if !!correspondentValue}
					<AutosaveInput {...correspondentFullNameField} />
				{/if}
			{/if}
		{/if}
		{#if ['normal', 'other'].includes(answer.kind)}
			<AutosaveTextarea {...formFields.content} />
		{/if}
		{#if answer.kind === 'other'}
			<AutosaveCheckbox {...formFields.includeInReports} />
		{/if}
		{#if showPossessionsBlocks}
			<PossessionsBlock header="Существующие объекты" />
			<DraftPossessionsBlock {answerId} />
		{/if}
		{#if showRequirementBlock}
			<DraftRequirementsBlock {answer} />
		{/if}
	</div>
</div>
<div class="col-12 col-sm-7 mt-1 p-0 pl-sm-3 float-right height__full ocr-screen">
	{#if $transmitter.recognizeError}
		<Message message={$transmitter.recognizeError} cssClass="mt-0" on:click={() => ($transmitter = { ...$transmitter, recognizeError: null })} />
	{/if}
	<div class="scan mb-5 p-0 flex h-100" class:dashed-border={isBlank(scan)}>
		<OCRScan {scan} withPossessionsBlocks={showPossessionsBlocks} on:needNextBatch={loadNextBatch} />
	</div>
</div>
<SwitchBtn bind:switchValue />
<CounterpartyModal fixedRoles={[correspondentKind]} on:create={({ detail: newId }) => setCounterparty(newId)} />
<Modal
	modalId="delete-answer-modal"
	index="0"
	submitButtonText="Удалить"
	submitButtonCss="btn-danger"
	submitButtonAction={answerDelete}
	cancelButtonAction={() => (deleteIconDisabled = false)}
	form={false}
>
	<div slot="body">
		<h3>Действительно хотите удалить {answer.kind === 'other' ? 'документ' : 'ответ'}?</h3>
	</div>
</Modal>
<style>
	.dashed-border {
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='grey' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
	}
	.height__full {
		height: calc(100vh - 130px);
	}
	.scan {
		background-color: inherit;
		justify-content: center;
	}
	.scrollable {
		overflow-y: auto;
		overflow-x: hidden;
	}

	@media (max-width: 576px) {
		.ocr-screen {
			position: absolute;
			height: calc(100vh - 200px);
		}
	}
</style>
