<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { toArray, isPresent } from 'utils/tools';
	import ErrorMessage from '~/svelte/components/message.svelte';
	import Form from '~/svelte/components/ui/form/_form.svelte';
	import Field from '~/svelte/components/ui/form/_form_field.svelte';
	import Select from '~/svelte/components/ui/svelte-select/select.svelte';
	import InputDate from '~/svelte/components/ui/input_date.svelte';
	import InputMoney from '~/svelte/components/ui/input_money.svelte';

	const dispatch = createEventDispatcher();

	export let disabled = false;
	export let formOptions;
	export let isCompleted;
	export const submitForm = () => {
		if ($form.kind == 'payable_collection') {
			$form.payer = null;
		}
		if (['asset_disposal', 'other'].includes($form.kind)) {
			$form.receivable_id = null;
		}
		return formControl.submitForm();
	};
	export const deleteItem = () => formControl.deleteItem();
	export const getItem = () => formControl.getItem();
	export const setItem = changes => formControl.setItem(changes);
	export const getData = () => data;
	export const setData = changes => (data = { ...data, ...changes });

	let formControl;
	let form, errorsBase, handleChange;
	let data = {};

	$: if (isPresent($form?.id) && !$form.bank_account_id) {
		$form.bank_account_id = 'cash';
	}

	const setInitialazedForm = instance => {
		form = instance.form;
		errorsBase = instance.errorsBase;
		handleChange = instance.handleChange;
	};

	const setDictionariesValues = values => {
		data = { ...values };
		data.receivables = [{ value: -1, label: 'Добавить требование', action: true }, ...data.receivables];
		data.bank_accounts = [{ value: -1, label: 'Добавить счёт', action: true }, ...data.bank_accounts, { value: 'cash', label: 'Наличные', action: false }];
	};

	const setSubmitParams = () => ({ bank_account_id: $form.bank_account_id == 'cash' ? null : $form.bank_account_id });

	const handleNewReceivable = () => dispatch('newReceivable');

	const handleNewBankAccount = () => dispatch('newBankAccount');
</script>

<Form
	bind:this={formControl}
	bind:isCompleted
	formOptions={{ ...formOptions, model: 'receipt', controller: 'receipts' }}
	{setDictionariesValues}
	{setSubmitParams}
	{setInitialazedForm}
>
	<!-- Дата поступления -->
	<Field name="date_at" label="Дата поступления" short={true} required={true}>
		<InputDate {disabled} name="date_at" bind:value={$form.date_at} klass="form-control required-border" />
	</Field>
	<!-- Сумма -->
	<Field name="sum" label="Сумма поступления (руб.)" short={true} required={true}>
		<InputMoney {disabled} name="sum" bind:value={$form.sum} klass="form-control required-border text-right" />
	</Field>
	<!-- Счёт, куда поступили деньги -->
	<Field name="bank_account_id" label="Счёт, куда поступили деньги" required={true}>
		<Select
			isDisabled={disabled}
			name="bank_account_id"
			items={data.bank_accounts}
			value={$form.bank_account_id}
			onchange={handleChange}
			on:action={handleNewBankAccount}
			containerClasses="form-control"
			placeholder="Выбрать счет"
		></Select>
	</Field>
	<!-- Вид поступления -->
	<Field name="kind" label="Вид поступления" required={true}>
		{#each toArray(data.kinds) as option}
			<label class="d-flex">
				<input {disabled} type="radio" bind:group={$form.kind} class="align-self-center m-r-xs" value={option.value} />
				{option.label}
			</label>
		{/each}
	</Field>

	{#if ['asset_disposal', 'bankrupt_salary', 'other'].includes($form.kind)}
		<!-- Плательщик -->
		<Field name="payer" label="Плательщик" required={true}>
			<input {disabled} name="payer" type="text" class="form-control" bind:value={$form.payer} on:change={handleChange} placeholder="ООО “Покупатель”" />
		</Field>
	{:else if $form.kind == 'payable_collection'}
		<!-- Требование к третьим лицам -->
		<Field name="receivable_id" label="Требование к третьим лицам" required={true}>
			<Select
				isDisabled={disabled}
				name="receivable_id"
				items={data.receivables}
				value={$form.receivable_id}
				onchange={handleChange}
				on:action={handleNewReceivable}
				containerClasses="form-control"
				placeholder="Выбрать требование"
			></Select>
		</Field>
	{/if}
	<!-- Номер документа -->
	<Field name="num" label="Номер документа" required={true}>
		<input {disabled} name="num" type="text" class="form-control" bind:value={$form.num} on:change={handleChange} placeholder="15" />
	</Field>
	<!-- Дата документа -->
	<Field name="num_at" label="Дата документа">
		<InputDate {disabled} name="num_at" bind:value={$form.num_at} klass="form-control" />
	</Field>
	<!-- Назначение платежа -->
	<Field name="note" label="Назначение платежа" required={true}>
		<textarea {disabled} name="note" rows="3" class="form-control" bind:value={$form.note} on:change={handleChange} placeholder="Оплата имущества…" />
	</Field>

	<ErrorMessage message={$errorsBase} on:click={() => ($errorsBase = null)} />
</Form>
