<!-- @format -->
<script>
	import { transmitter, procedure, dctReady, fieldsReady, currentUserIsAdmin, lastFinancialStateDct, bankruptIsPerson } from 'base_stores';
	import { dcts, dct, dctId, dctStatus, requiredFieldsFilled, substitutions, isOldRosreestrRequest } from './stores/stores';
	import { onMount } from 'svelte';
	import { fetchGet, fetchPost, fetchPut, fetchDelete } from 'utils/fetch';
	import Header from '~/svelte/components/header.svelte';
	import Modal from '~/svelte/components/modal.svelte';
	import ErrorMessage from '~/svelte/components/message.svelte';
	import Editor from '~/svelte/wysiwyg_editors/tiny_mce/Editor.svelte';
	import Dashboard from './dashboard.svelte';
	import FormFields from './form_fields.svelte';
	import Template from './template.svelte';
	import SwitchBtn from '~/svelte/components/ui/switch_btn.svelte';
	import fileDownload from 'js-file-download';
	import { isPresent, isBlank } from 'utils/tools';
	import { encodeTemplate } from './conformities';
	import { templateFieldSubstitution } from './template.js';
	import TagList from '~/svelte/components/ui/tag_list.svelte';
	import moment from 'moment';

	// напоминалка! -> const dct = derived([transmitter, dct], ([$transmitter, $dct]) => ($transmitter?.dct || $dct))

	const dctKinds = { request: 'запрос', notification: 'увед.' };

	const petitionsKinds = [
		'petition_for_publications_inclusion',
		'petition_for_introduction_of_bankruptcy_proceedings',
		'petition_to_transfer_to_property_realization',
		'petition_for_inclusion_of_materials_of_sk',
		'petition_of_postponement',
		'petition_for_familiarization_with_electronic_file',
		'petition_for_familiarization_with_materials_of_case',
		'application_for_issuance_of_copy_of_judicial_act',
		'petition_for_reclamation_of_evidence_by_zags',
		'petition_for_reclamation_of_evidence_by_sfr',
		'petition_for_reclamation_of_evidence',
	];

	const editorId = `tinymce_${Math.floor(Math.random() * 1000000000)}_${String(Date.now())}`;

	let template;
	let currentDctId;
	let dctName = {};
	let errors = [];
	let templateErrors = [];
	let switchValue;
	let templateEditing = false;
	let correspondentKind;
	let dctCategory;
	let dctKind;
	let tabsField;
	let docsContainerField;
	let leftArrow = { disabled: true };
	let rightArrow = {};
	let previewPageWidth;
	let toxEditorHeaderHeight;
	let headerHeight;
	let deleteIconDisabled = false;

	$: editorConfig = {
		menubar: false,
		selector: `#${editorId}`,
		theme: 'rafsilver',
		skin: 'oxide-raf',
		content_css: 'oxide-raf',
		placeholder: 'Печатайте здесь...',
		plugins:
			$dctStatus == 'draft' && templateEditing
				? 'anchor autolink autoresize charmap codesample lists advlist searchreplace table wordcount nonbreaking raftags'
				: 'autoresize',
		toolbar:
			$dctStatus == 'draft' && templateEditing
				? `undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | align lineheight |
      numlist bullist outdent indent | table | searchreplace removeformat nonbreaking${$currentUserIsAdmin ? ' | raftags' : ''}`
				: '', // raftags`,
		format_empty_lines: true,
		format_noneditable_selector: 'span',
		font_size_formats: '6pt 7pt 8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 18pt 20pt 24pt 30pt 36pt 48pt',
		default_font_stack: ['Arial', 'Open Sans', 'Helvetica Neue', 'Helvetica', 'sans-serif', 'Times New Roman'],
		font_family_formats:
			'Arial=Liberation Sans,FreeSans,Arial,open-sans; Times New Roman=Liberation Serif,FreeSerif,Times New Roman; Courier New=Courier New,FreeMono;',
		line_height_formats: '1 1.1 1.2 1.3 1.4 1.5 1.6 1.7 1.8 2 2.2 2.5 3 3.5 4',
		table_default_styles: { 'font-family': 'Arial', 'font-size': '11pt', width: '100%' },
		indent: true,
		indentation: '30pt',
		indent_use_margin: true,
		raftags: [
			{
				name: 'Поля',
				tags: tags,
			},
		],
		toolbar_mode: 'wrap',
		statusbar: false,
		tinycomments_mode: 'embedded',
		tinycomments_author: 'Author name',
		language_url: `${location.origin}/js/wysiwyg_editors/tinymce/langs/ru.js`,
		language: 'ru',
		save_enablewhendirty: false,
		noneditable_class: 'raf_noneditable',
	};

	$: $dctStatus = $dct && $dct.status;
	$: currentDctIndex = $dcts.findIndex(dct => dct.id === currentDctId);

	// здесь происходит сохранение изменения из одного документа во все документы группы
	$: if ($substitutions && currentDctId === $dctId) {
		let changes = {};
		const currentSubstitutions = $transmitter.dcts[currentDctIndex].substitutions;

		for (let key of ['date', 'number']) {
			$transmitter.dct[key] = $dct.substitutions[key];
		}

		for (let key in $dct.substitutions) {
			if (currentSubstitutions[key] !== $dct.substitutions[key]) {
				if ($dctReady) {
					changes[key] = $dct.substitutions[key];
				} else {
					currentSubstitutions[key] = $dct.substitutions[key];
				}
			}
		}

		if (isPresent(Object.keys(changes))) {
			actualizeDcts(changes);
			fillTemplate();
		}
	}

	const handleToggleStatus = () => {
		fillTemplate();
	};

	const actualizeDcts = async changes => {
		const singleChangesKeys = ['appendices'];

		$transmitter.dcts.forEach((currentDct, index) => {
			let filteredChanges = Object.fromEntries(Object.entries(changes).filter(([key, _value]) => !singleChangesKeys.includes(key)));

			if ($dct.id != currentDct.id) {
				filteredChanges = Object.fromEntries(Object.entries(filteredChanges).filter(([key, _value]) => !key.match(/^(correspondent|realty_location)_/)));

				if (isPresent(filteredChanges)) {
					fetchPut(`/api/private/documents/${currentDct.id}`, { dct: filteredChanges, procedure_id: $procedure.id }).then(
						response => ($transmitter.dcts[index].substitutions = response.dct.substitutions),
					);
				}
			} else {
				$transmitter.dcts[index].substitutions = { ...$transmitter.dcts[index].substitutions, ...filteredChanges };
			}
		});
	};

	// Пока оставить currentDctId !== $dctId.
	// Если будет проверка только на currentDctId,
	// то transmitter.dct при каждой операции мутируется,
	// а нужно чтобы transmitter.dct менялся только при переключении табов
	$: if (currentDctId != $dct?.id) {
		$fieldsReady = {};
		$requiredFieldsFilled = {};
		$transmitter.dct = $dcts[currentDctIndex];
		$dctStatus = $transmitter.dct.status;
		correspondentKind = $transmitter.dct.correspondent_kind;
		for (let d of $dcts) {
			dctName[d.id] = buildDctName(d, false);
		}
	}

	const deleteDct = () => {
		let backpath = location.pathname.split('/edit')[0];

		// TODO: т.к. категория для исходящих называется outgoing, а индекс для неё outgoings,
		// не достаточно выделить location.pathname
		// необходимо переименовать категорию в outgoings !!!!!
		if (location.pathname.match(/^\/documents\/outgoing(s)?/)) {
			backpath = `${backpath}s`;
		}

		fetchDelete(`/api/private/documents/${currentDctId}`, { procedure_id: $procedure.id }).then(_response => {
			if ($transmitter.dcts.length > 1) {
				//location.href = location.href.replace(`,${currentDctId}`, '').replace(`${currentDctId},`, '').replace(currentDctId, '')
				const dctsCount = $transmitter.dcts.length - 1;
				let newIndex = 0;
				$transmitter.dcts = $transmitter.dcts.filter((d, i) => {
					if (d.id == currentDctId && i + 1 <= dctsCount) {
						newIndex = i;
					}
					return d.id != currentDctId;
				});
				$transmitter.dct = $dcts[newIndex];
				currentDctId = $transmitter.dct.id;
				deleteIconDisabled = false;
			} else {
				location.href = backpath;
			}
		});
	};

	const buildDctName = (dct, ext) => {
		if (isBlank(dct.kind_name)) {
			return buildDctFullName(dct);
		} else if (['financial_analysis', 'reports'].includes(dct.category)) {
			return dct.kind_name;
		} else if (dct.correspondent_kind === 'rosreestr') {
			return `${dct.correspondent_kind_name} (${dctKinds[dct.kind]})`;
		} else if (petitionsKinds.includes(dct.kind)) {
			return ext || dct.id === currentDctId ? buildDctFullName(dct) : dct.kind_name.split(' ')[0];
		} else {
			return dct.correspondent_kind_name;
		}
	};

	$: dctFullName = buildDctFullName($dct);

	const buildDctFullName = dct => {
		const dctKind = dct && dct.kind;
		const templateName = dct && dct.template_name;
		if (templateName) {
			return templateName;
		} else if (petitionsKinds.includes(dctKind)) {
			return dct.kind_name;
		} else {
			return dct && [dct.kind_name, dct.correspondent_kind_name_as_destination].filter(i => i).join(' ');
		}
	};

	const comeBack = () => {
		location.href = document.referrer;
	};

	$: if (currentDctId) {
		templateEditing = false;
		fillTemplate();
	}

	const getTemplate = () => {
		tags = tags.map(t => [t[0], t[1], fieldValue(t[0])]);

		const baseTemplate = $dct && ($dct.category === 'financial_analysis' || templateEditable ? $dct.template : encodeTemplate($dct.template, null));
		const splitedTemplate = baseTemplate && baseTemplate.split('%%ADDITIONAL_TEMPLATE_FRAGMENTS%%');
		return (splitedTemplate && splitedTemplate[0]) || '';
	};

	const fillTemplate = initTemplate => {
		if (isBlank(initTemplate)) {
			initTemplate = getTemplate() || '';
		}

		template = initTemplate
			.replace(/>\s+</g, '><')
			.replace(/<p((?!<).)*?>(\s*<span((?!<))*.)*%%APPENDICES%%(((?!>).)*?)(<\/span>)*\s*<\/p>/g, '%%APPENDICES_LIST%%')
			.replace(/(%%[0-9A-Z_-]+%%)/g, str => {
				const fValue = fieldValue(str);
				const fValueBlank =
					/class="empty"/.test(fValue) || /class='empty'/.test(fValue) || /class="required-empty"/.test(fValue) || /class='required-empty'/.test(fValue);

				if (str == '%%ARBITRARY_TEXT%%' && fValueBlank) {
					return str;
				} else if (str == '%%APPENDICES_LIST%%') {
					return `<div class="mce-content-raf-tag-block" data-tag="${str}">${fValue}</div>`;
				} else {
					const tag = tags.find(t => t[0] == str);
					const title = tag ? tag[1] : '';
					return `<span class="mce-content-raf-tag${fValueBlank ? '__empty' : ''}${str == '%%SIGNATURE%%' ? ' img' : ''} raf_noneditable ${$dctStatus}" title="${title}" data-tag="${str}">${fValueBlank ? '_____' : fValue}</span>`;
				}
			}) // удалить следующую строку после реализации задачи 1941
			.replace(/<p((?!<).)*?><span((?!<).)*?>%%ARBITRARY_TEXT%%<\/span><\/p>/g, '');
		//.replace(/\<p((?!<).)*?\>\s+\<span((?!<).)*?\>%%ARBITRARY_TEXT%%\<\/span\>\s+\<\/p\>/g, '')
	};

	const fieldValue = tag => {
		const value = templateFieldSubstitution(tag).preview;
		return isPresent(value) ? value : '';
	};

	let tags = [
		['%%DATE%%', 'дата'],
		['%%NUMBER%%', 'номер'],
		['%%CORRESPONDENT_FULL_NAME%%', 'корреспондент, полное наименование'],
		['%%BANKRUPT_FULL_NAME%%', 'банкрот, полное имя'],
		['%%ARBITR_MANAGER_FULL_NAME%%', 'АУ, полное имя'],
		['%%BANKRUPT_INN%%', 'банкрот, ИНН'],
		['%%BANKRUPT_SNILS%%', 'банкрот, СНИЛС'],
	];

	const prepareTemplate = t => {
		return (t || '')
			.replace(/>\s+</g, '><')
			.replace(/<div((?!<).)*?mce-content-raf-tag-block((?!<div|div>).)*?div>/g, str => {
				const field = str.match(/[%#]{2}[A-Z][A-Z_]*[A-Z][%#]{2}/);
				return isPresent(field) ? field : ''; //`<p>${field}</p>` : ''
			})
			.replace(/<span((?!<).)*?mce-content-raf-tag((?!<).)*?((?!<span|span>).)*?(<span(.)*?span>)*?((?!<span|span>).)*?span>/g, str =>
				str.match(/[%#]{2}[A-Z][A-Z_]*[A-Z][%#]{2}/),
			);
		//.replace(/<p>&nbsp;<\/p>$/, '');
	};

	$: if (template && !templateEditing) {
		fillTemplate();
	}

	const changeTemplate = async () => {
		let newTemplate = null;
		if (templateEditing) {
			newTemplate = prepareTemplate(template);
		}

		if (templateEditing && newTemplate != $dct.template) {
			try {
				const response = $dct.template_id
					? await fetchPut(`/api/private/templates/${$dct.template_id}`, { template: { content: newTemplate } })
					: await fetchPost('/api/private/templates', { template: { content: newTemplate }, dct_id: $dct.id });

				$transmitter.dcts[currentDctIndex].template = $transmitter.dct.template = response.template.content;
				$transmitter.dcts[currentDctIndex].template_id = $transmitter.dct.template_id = response.template.id;
				//				fillTemplate();
			} catch (e) {
				templateErrors = e;
				throw e;
			} finally {
				//loading = false
			}
		}

		templateEditing = !templateEditing;
	};

	const resetTemplateChanges = () => {
		fillTemplate($dct.basic_template);
	};

	const cancelTemplateChanges = () => {
		//resetTemplateChanges()
		fillTemplate($dct.template);
		templateEditing = !templateEditing;
	};

	const downloadTemplate = () => {
		const newTemplate = prepareTemplate(template);
		fileDownload(newTemplate, `template-${correspondentKind}-${$procedure.phase}-${moment().format('YYYYMMDD-HHmmss')}.txt`);
	};

	$: dctCategory = $dct && $dct.category;
	$: dctKind = $dct && $dct.kind;

	//========================================================================================================
	// пока не все шаблоны доступны для редактирования в Tinymce
	$: templateEditable =
		!/##IF-[0-9A-Z_-]+##/.test(template) &&
		dctCategory != 'reports' &&
		!['newspaper', 'fedresource', 'rosreestr'].includes(correspondentKind) &&
		!(correspondentKind == 'bank' && dctKind == 'closure_request') &&
		!(correspondentKind == 'bank' && dctKind == 'notification_request' && $procedure.phase == 'supervision') &&
		!(correspondentKind == 'rtn' && dctKind == 'statement' && ['supervision', 'bankruptcy_proceedings'].includes($procedure.phase));
	//========================================================================================================

	function scrollTab(direction) {
		const match = tabsField.style.transform.match(/translateX\((-?\d+)px\)/);
		const currentX = match ? parseInt(match[1], 10) : 0;
		const newX = currentX + direction * 300;
		if (newX < 0) {
			const maxX = docsContainerField.offsetWidth - tabsField.offsetWidth;
			if (maxX >= newX) {
				leftArrow.disabled = false;
				rightArrow.disabled = true;
				tabsField.style.transform = `translateX(${maxX}px)`;
			} else {
				leftArrow.disabled = false;
				rightArrow.disabled = false;
				tabsField.style.transform = `translateX(${newX}px)`;
			}
		} else {
			leftArrow.disabled = true;
			rightArrow.disabled = false;
			tabsField.style.transform = 'translateX(0px)';
		}
	}

	onMount(() => {
		$transmitter.dct = $dcts && $dcts[0];
		currentDctId = $dctId;
		correspondentKind = $transmitter.dct.correspondent_kind;

		if ($bankruptIsPerson && $dct?.category == 'reports' && $dct?.kind == 'productive') {
			fetchGet('/api/private/documents/get_last_financial_state_dct', { procedure_id: $procedure.id, dct_only: true }).then(
				response => ($lastFinancialStateDct = response.dct || {}),
			);
		}

		for (let d of $dcts) {
			dctName[d.id] = buildDctName(d, false);
		}

		fillTemplate();
	});
</script>

<Header withTabs={$dcts && $dcts.length > 1} bind:headerHeight>
	<span slot="name">
		<span role="button" tabindex="0" style="vertical-align: middle;" on:click={comeBack} on:keypress|stopPropagation>
			<iconify-icon icon="fa-solid:chevron-left" class="m-r-xs hoverable" />
		</span>
		{dctFullName}
	</span>
	<div slot="tabs" style="display: flex">
		{#if $dcts && $dcts.length > 1}
			<div class="docs-container" bind:this={docsContainerField}>
				<div class="btn-group btn-group-toggle m-t-none" data-toggle="buttons" bind:this={tabsField}>
					{#each $dcts as dct}
						<label
							class="btn btn-sm btn-outline-secondary btn-document mb-2"
							class:cursor_pointer={$dctReady}
							on:mouseover={() => (dctName[dct.id] = buildDctName(dct, true))}
							on:mouseout={() => (dctName[dct.id] = buildDctName(dct, false))}
							on:focus={() => (dctName[dct.id] = buildDctName(dct, true))}
							on:blur={() => (dctName[dct.id] = buildDctName(dct, false))}
						>
							<input type="radio" bind:group={currentDctId} value={dct.id} disabled={!$dctReady} />
							{dctName[dct.id]}
						</label>
					{/each}
				</div>
			</div>
			<div class="arrow-button-container">
				<button class="arrow-button" bind:this={leftArrow} on:click={() => scrollTab(1)}>
					<iconify-icon icon="fa-solid:chevron-left" class="m-r-xs hoverable" style="padding-left: 2px" />
				</button>
				<button class="arrow-button" bind:this={rightArrow} on:click={() => scrollTab(-1)}>
					<iconify-icon icon="fa-solid:chevron-right" class="m-r-xs hoverable" style="padding-right: 1px" />
				</button>
			</div>
		{/if}
	</div>
	<div slot="dashboard">
		<Dashboard
			{templateEditable}
			{switchValue}
			bind:deleteIconDisabled
			bind:templateEditing
			bind:errors
			on:changeTemplate={changeTemplate}
			on:resetTemplateChanges={resetTemplateChanges}
			on:cancelTemplateChanges={cancelTemplateChanges}
			on:downloadTemplate={downloadTemplate}
			on:toggleStatus={handleToggleStatus}
		/>
	</div>
</Header>
<div class="flex m-b-lg footer-margin">
	<div class="document-form">
		<div class="row" style="height: calc(100vh - 92px); margin-right: 0;">
			<div class="col-12 col-sm-5 footer-margin" class:hidden-mobile={!switchValue} style="overflow: auto; height: 100%; padding-right: 0;">
				<div class="overlay">
					{#if dctCategory === 'outgoing'}
						<TagList tags={$dct.tags} kind="Dct" kindId={$dct.id} />
					{/if}
					<ErrorMessage cssClass="m-t-none" message={errors} on:click={() => (errors = [])} />
					<FormFields />
				</div>
			</div>
			{#if $dctStatus == 'draft' && templateEditing}
				<div class="col-12 col-sm-7 offset-sm-5 footer-margin" class:hidden-mobile={switchValue} style="position: absolute;">
					<sub class="beta" style:top={`${headerHeight + toxEditorHeaderHeight - 15}px`}>beta</sub>
				</div>
			{/if}
			<div class="col-12 col-sm-7 footer-margin" class:hidden-mobile={switchValue} style="overflow: auto; height: 100%; padding-right: 0;">
				<div class="preview">
					<div class:preview__page={!templateEditing} class:editing__page={templateEditing} bind:offsetWidth={previewPageWidth}>
						<ErrorMessage message={templateErrors} on:click={() => (errors = [])} />
						{#if $isOldRosreestrRequest}
							<h3 style="color: #676a6c;">
								<p>Изменение и предпросмотр недоступны из-за изменения шаблона документа.</p>
								<p>Документ можно скачать или распечатать.</p>
							</h3>
						{:else if $dctStatus == 'draft' && templateEditing}
							<form>
								<!--Editor id={editorId} bind:value={template} conf={editorConfig}/-->
								<Editor
									id={editorId}
									conf={editorConfig}
									refreshEditor={true}
									bind:value={template}
									bind:toxEditorHeaderHeight
									--editor-header-width={`${previewPageWidth}px`}
									--editor-header-margin-left={`-${previewPageWidth * 0.0952}px`}
									--editor-header-height={`${toxEditorHeaderHeight}px`}
								/>
							</form>
						{:else if templateEditable}
							<form>
								<Editor id={editorId} refreshEditor={true} bind:value={template} conf={editorConfig} />
							</form>
						{:else}
							<Template />
						{/if}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<SwitchBtn bind:switchValue />
<Modal
	modalId="delete-document-modal"
	submitButtonText="Удалить"
	submitButtonCss="btn-danger"
	submitButtonAction={deleteDct}
	cancelButtonAction={() => (deleteIconDisabled = false)}
>
	<h2 slot="header">Удалить документ?</h2>
</Modal>

<style lang="scss">
	.beta {
		background: #ed5565;
		border-radius: 1em;
		color: #fff;
		display: inline-block;
		font-size: 0.7em;
		font-weight: 600;
		line-height: 1.5;
		padding: 0 0.1em 0 0.4em;
		letter-spacing: 0.4em;
		vertical-align: text-bottom;
		text-rendering: optimizeLegibility;

		position: fixed;
		z-index: 9;
		right: 33px;
		height: 1.5em;
	}

	div[slot$='dashboard'],
	.document-form {
		width: 100%;
	}

	.btn-group {
		transition: transform 0.4s ease;
	}

	.docs-container {
		overflow: hidden;
	}

	.arrow-button-container {
		margin-bottom: 10px;
		display: flex;
		margin-left: 5px;
		align-items: center;
	}

	.arrow-button {
		margin: 5px;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background-color: transparent;
		display: flex;
		text-align: center;
		align-items: center;
		justify-content: center;
		transition: background-color 0.3s ease;
		cursor: pointer;
		border: 1px solid #6c757d;
	}

	.arrow-button:disabled {
		border-color: #abadaf;
		pointer-events: none;
	}

	.arrow-button:not([disabled]):hover {
		background-color: #ccc;
	}

	.arrow-button:disabled iconify-icon[icon='fa-solid:chevron-left'],
	.arrow-button:disabled iconify-icon[icon='fa-solid:chevron-right'] {
		color: #abadaf;
	}

	.arrow-button iconify-icon[icon='fa-solid:chevron-left'],
	.arrow-button iconify-icon[icon='fa-solid:chevron-right'] {
		margin-right: 0;
		text-align: center;
		padding: 0px 4px 0px 3px;
	}

	.preview {
		flex-direction: column;
		align-items: center;
		color: #000;
		box-shadow:
			0 2px 2px 0 rgba(0, 0, 0, 0.14),
			0 3px 1px -2px rgba(0, 0, 0, 0.2),
			0 1px 5px 0 rgba(0, 0, 0, 0.12);
	}

	.preview__page {
		--aspect-ratio: 210 / 297;
		box-sizing: border-box;
		background-color: #fff;
		padding: 7.14% 7.14% 7.14% 9.52%;
		position: relative;
		margin-top: 1px;
	}

	.editing__page {
		--aspect-ratio: 210 / 297;
		box-sizing: border-box;
		background-color: #fff;
		padding: 5px 7.14% 7.14% 9.52%;
		position: relative;
		margin-top: 1px;
	}

	@media (max-width: 576px) {
		/*.preview__page, .editing__page {
      margin-top: 35px;
    }*/

		.beta {
			right: 7px;
		}
	}
</style>
